import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Hidden,
  Menu,
  ListItemAvatar,
  Avatar,
  IconButton,
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Language from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/core/styles';
import Norway from './norway.svg';
import Britain from './britain.svg';

const useStyles = makeStyles({
  barUser: {
    marginLeft: 'auto',
    marginRight: '2rem',
    display: 'inline-flex',
  },
  avatarWrapper: {
    margin: 'auto 0.7rem auto 0',
    width: '1.5rem',
    height: '1.5rem',
  },
  menuMobile: {
    marginLeft: 'auto',
    display: 'inline-flex',
  },
  menu: {
    marginLeft: 'auto',
    display: 'inline-flex',
    marginRight: '2rem',
  },
  language: {
    padding: '0.5rem',
  },
  avatar: {
    borderRadius: 0,
    height: '100%',
  },
  menuitem: {
    height: 'auto',
  },
});

const LanguageSelector = ({
  languages, setLang, flavour,
}) => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(null);

  return (
    [
      <Hidden smDown key="hidden1">
        <div className={classes.menu}>
          <Button
            focusRipple
            className={clsx(classes.menu, classes.language)}
            onClick={e => setOpenMenu(e.currentTarget)}
            color="primary"
          >
            <Language
              color="primary"
              style={{ marginRight: '1rem' }}
            />
            Language
          </Button>
        </div>
      </Hidden>,
      <Hidden mdUp key="hidden2">
        <div className={classes.menuMobile}>
          <IconButton onClick={e => setOpenMenu(e.currentTarget)}>
            <Language color="primary" />
          </IconButton>
        </div>
      </Hidden>,
      <div key="menu">
        {languages.some(item => item.active) && (
          <Menu
            open={Boolean(openMenu)}
            onClose={() => setOpenMenu(null)}
            anchorEl={openMenu}
          >
            {languages.map(lang => (
              <MenuItem
                key={lang.code}
                className={classes.menuitem}
                value={lang.code}
                onClick={() => {
                  setLang(lang.code); setOpenMenu(null);
                  localStorage.setItem('language', lang.code);
                  document.documentElement.lang = lang.code;
                }}
              >
                <ListItemAvatar>
                  <Avatar src={lang.code !== 'en' ? Norway : Britain} className={classes.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={lang.name}
                  secondary={flavour[lang.code]}
                />
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>,
    ]
  );
};

LanguageSelector.propTypes = {
  languages: PropTypes.array.isRequired,
  setLang: PropTypes.func.isRequired,
  flavour: PropTypes.object,
};

LanguageSelector.defaultProps = {
  flavour: {
    nb: 'Alt innhold er tilgjengelig.',
    en: 'Some content is available.',
    nn: 'Noko av innhaldet er tilgjengelig.',
  },
};

export default LanguageSelector;
