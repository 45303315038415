import React from 'react';
import PropTypes from 'prop-types';
import compactlogo from './kompakt_standard.svg';
import horizontallogo from './Horisontal standard.svg';
import verticallogo from './vertikal_standard.svg';
import notextlogo from './Trondheim kommunevåpen.svg';

const LogoTypes = {
  compact: compactlogo,
  horizontal: horizontallogo,
  vertical: verticallogo,
  notext: notextlogo,
};
const Logo = ({ height, variant, ...otherProps }) => (
  <img src={LogoTypes[variant]} alt="Trondheim kommunes logo" style={height ? { height: `${height}rem` } : undefined} {...otherProps} />
);

Logo.propTypes = {
  /** Height of the logo in rems */
  height: PropTypes.string,
  variant: PropTypes.oneOf([
    'compact',
    'horizontal',
    'vertical',
    'notext',
  ]).isRequired,
};

Logo.defaultProps = {
  height: undefined,
};

export default Logo;
