export default (name, transformFunc, defaultPayload) => (state = {
  fetching: false,
  fetched: false,
  payload: defaultPayload,
  params: {},
  error: undefined,
}, action) => {
  switch (action.type) {
    case `FETCH_${name}_REQUEST`: {
      return {
        fetching: true,
        fetched: false,
        payload: defaultPayload,
        params: action.params,
      };
    }

    case `FETCH_${name}_SUCCESS`: {
      return {
        fetching: false,
        fetched: true,
        payload: transformFunc ? transformFunc(action.payload) : action.payload,
        params: action.params,
      };
    }

    case `FETCH_${name}_ERROR`: {
      return {
        error: action.error,
        fetching: false,
        fetched: true,
        payload: defaultPayload,
        params: action.params,
      };
    }

    case `FETCH_${name}_CLEAR`: {
      return {
        fetching: false,
        fetched: false,
        payload: defaultPayload,
        params: {},
        error: undefined,
      };
    }

    default:
      return state;
  }
};
