const fetchRequest = (name, params) => ({
  type: `FETCH_${name}_REQUEST`,
  params,
});

const fetchSuccess = (name, payload, params) => ({
  type: `FETCH_${name}_SUCCESS`,
  payload,
  params,
});

const fetchError = (name, error, params) => ({
  type: `FETCH_${name}_ERROR`,
  error,
  params,
});

export const doFetch = (name, fetchPromise, params) => (dispatch) => {
  dispatch(fetchRequest(name, params));
  const upperCaseName = name.toUpperCase();
  return fetchPromise
    .then(payload => dispatch(fetchSuccess(upperCaseName, payload, params)))
    .catch(error => dispatch(fetchError(upperCaseName, error, params)));
};

export const fetchClear = name => ({
  type: `FETCH_${name}_CLEAR`,
});
