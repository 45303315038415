import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Horizontal, EnglishHorizontal as English, KommuneVaapen } from '../Logo';
import { MobileView, DesktopView } from '../Views';

const useStyles = makeStyles(theme => ({
  appBar: {
    // backgroundColor: '#fff',
    paddingLeft: 'calc(100vw - 100%)',
  },
  appBarContent: {
    maxWidth: '81.3rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  logo: {
    // float: 'left',
    height: '5.4rem',
  },
  styled: {
    boxShadow: '0 0 28px rgba(0,0,0,.5)',
    borderBottom: `1rem solid ${theme.palette.primary.main}`,
  },
}));

const Navbar = ({
  children, variant, language, withLinkProps,
}) => {
  const classes = useStyles();
  const RouterLink = withLinkProps.Link;

  return (
    <div className={clsx(variant === 'styled' && classes.styled)}>
      <CssBaseline />
      <Paper className={classes.appBar} elevation={0}>
        <Toolbar className={classes.appBarContent}>
          <RouterLink to={withLinkProps.url}>
            <MobileView><img src={KommuneVaapen} alt="Logo" className={classes.logo} /></MobileView>
            <DesktopView><img src={language === 'en' ? English : Horizontal} alt="Logo" className={classes.logo} /></DesktopView>
          </RouterLink>
          {children}
        </Toolbar>
      </Paper>
    </div>
  );
};

Navbar.propTypes = {
  children: PropTypes.any,
  withLinkProps: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'styled']),
  language: PropTypes.string,
};

Navbar.defaultProps = {
  children: null,
  withLinkProps: null,
  variant: 'standard',
  language: 'nb',
};


export default Navbar;
